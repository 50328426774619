<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="700"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} Price</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" md="12">
              Is Free ?
              <v-switch
                  v-model="product_price.is_free"
                  :label="product_price.is_free ? 'Yes':'No'"
                  :error="$v.product_price.is_free.$error"
              ></v-switch>
              <div class="text-danger" v-if="$v.product_price.is_free.$error">
                This information is required
              </div>
              <span class="text-danger" v-if="$v.product_price.is_free.$error"
              >This information is required</span
              >
            </v-col>
            
            <v-col cols="12" md="6" v-if="!this.edit && currentUser.access_type == 'federal'">
              <v-select
                label="Score *"
                outlined 
                dense 
                v-model="product_price.score_id"
                :error="$v.product_price.score_id.$error"
                :items="scores"
                item-text="name"
                item-value="id"
              ></v-select>
              <span class="text-danger" v-if="errors.score_id" >**{{errors.score_id[0]}}</span>
              <span class="text-danger" v-if="$v.product_price.score_id.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="6" v-if="this.edit">
              <v-text-field
                  disabled
                  label="SCORE*"
                  outlined
                  dense
                  v-model="product_price.score_name"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field 
                label="Price *" 
                outlined 
                :error="$v.product_price.price.$error"
                dense
                type="number" 
                v-model="product_price.price"
              ></v-text-field>
              <span class="text-danger" v-if="errors.price" >**{{errors.price[0]}}</span>
              <span class="text-danger" v-if="$v.product_price.price.$error">This information is required</span>
            </v-col>
            <v-col cols="12" md="6" v-if="is_product_group_exam">
              <v-text-field
                  label="Group price per additional candidates"
                outlined
                dense
                type="number"
                v-model="product_price.group_price_per_additional_candidates"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-switch
                  v-model="product_price.on_special"
                  :error="$v.product_price.on_special.$error"
                  label="On Special"
              ></v-switch>
              <span class="text-danger" v-if="$v.product_price.on_special.$error">This information is required</span>
              <span class="text-danger" v-if="errors.on_special">{{ errors.on_special[0] }}</span>
            </v-col>

            <v-col cols="12" md="6" v-if="product_price.on_special">
              <v-text-field 
                label="On Special Price *" 
                outlined 
                :error="$v.product_price.on_special_price.$error"
                dense
                type="number" 
                v-model="product_price.on_special_price"
              ></v-text-field>
              <span class="text-danger" v-if="errors.on_special_price" >**{{errors.on_special_price[0]}}</span>
              <span class="text-danger" v-if="$v.product_price.on_special_price.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="6" v-if="product_price.on_special">
              <v-text-field 
                label="Special Pricing Text *" 
                outlined 
                dense
                :error="$v.product_price.special_pricing_text.$error"
                v-model="product_price.special_pricing_text"
              ></v-text-field>
              <span class="text-danger" v-if="errors.special_pricing_text" >**{{errors.special_pricing_text[0]}}</span>
              <span class="text-danger" v-if="$v.product_price.special_pricing_text.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="6" v-if="product_price.on_special">
              <v-menu
                ref="menu"
                no-title
                v-model="on_special_start_date"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="product_price.on_special_start_date"
                    label="On Special view from date *"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    :error="$v.product_price.on_special_start_date.$error"
                    v-on="on"
                    dense
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                    no-title
                    v-model="product_price.on_special_start_date"
                    :active-picker.sync="activePicker"
                    :min="currentDate"
                    @change="save"
                ></v-date-picker>
              </v-menu>
              <div
                class="text-danger"
                v-if="$v.product_price.on_special_start_date.$error"
              >
                This information is required
              </div>
              <span class="text-danger" v-if="errors.on_special_start_date" >*{{errors.on_special_start_date[0]}}</span>
            </v-col>

            <v-col cols="12" md="6" v-if="product_price.on_special">
              <v-menu
                ref="menu"
                no-title
                v-model="on_special_end_date"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="product_price.on_special_end_date"
                    label="On Special view up to date *"
                    append-icon="mdi-calendar"
                    readonly
                    :error="$v.product_price.on_special_end_date.$error"
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                    no-title
                    v-model="product_price.on_special_end_date"
                    :active-picker.sync="activePicker"
                    :min="product_price.on_special_start_date"
                    @change="save"
                ></v-date-picker>
              </v-menu>
              <div
                    class="text-danger"
                    v-if="$v.product_price.on_special_end_date.$error"
                >
                    This information is required
                </div>
                <span class="text-danger" v-if="errors.on_special_end_date" >*{{errors.on_special_end_date[0]}}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="btn btn-primary"
            dark
            medium
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
        <v-btn
            color="btn btn-standard"
            dark
            medium
            @click="closeDialog">
          Cancel
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import ProductPriceService from "@/services/product/product-price/ProductPriceService";

const product_price = new ProductPriceService();

export default {
  validations: {
    product_price: {
      product_id : {},
      score_id : {required},
      price : {required},
      is_free : {required},
      on_special_price : {
        required: requiredIf(function (nestedModel) {
          return this.product_price.on_special;
        })
      },
      on_special : {required},
      special_pricing_text : {
        required: requiredIf(function (nestedModel) {
          return this.product_price.on_special;
        })
      },
      on_special_start_date : {
        required: requiredIf(function (nestedModel) {
          return this.product_price.on_special;
        })
      },
      on_special_end_date : {
        required: requiredIf(function (nestedModel) {
          return this.product_price.on_special;
        })
      },
    }
  },
  data() {
    return {
      title: "",
      edit: false,
      errors: [],
      dialog: false,
      loading: false,
      currentUser : {},
      scores : [],
      product_price: {
        product_id : null,
        score_id : null,
        price : null,
        is_free : 0,
        group_price_per_additional_candidates:null,
        on_special_price : null,
        on_special : 0,
        special_pricing_text : null,
        on_special_start_date : '',
        on_special_end_date : '',
      },
      on_special_start_date: '',
      on_special_end_date: '',
      activePicker: null,
      currentDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      is_product_group_exam:false,
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods: {
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    createOrEditProductPrice(price,isGroupExam) {
      this.openDialog();
      if(this.currentUser.access_type == 'score') {
        if(price == null) {
          this.edit = false;
          this.title = "Add";
        }else {
          this.edit = true;
          this.title = "Edit";
          this.product_price = price;
        }
      }
      this.is_product_group_exam=isGroupExam;
    },
    createProductPrice(isGroupExam) {
      this.openDialog();
      this.edit = false;
      this.title = "Add";
      this.is_product_group_exam=isGroupExam;
    },
    editProductPrice(item,isGroupExam) {
      this.openDialog();
      this.edit = true;
      this.product_price = item;
      this.title = "Edit";
      this.is_product_group_exam=isGroupExam;
    },
    createOrUpdate() {
      if(this.currentUser.access_type == 'score') {
        this.product_price.score_id = this.currentUser.score_id;
      }
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } 
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.loading = true;
      this.product_price.product_id = this.$route.params.productId;
      product_price
      .create(this.product_price)
      .then(response => {
        this.$snotify.success("Price added");
        this.resetForm();
        this.closeDialog();
        this.loading = false;
        this.$emit('refresh');
      })
      .catch((err) => {
        this.loading = false;
        this.errors = err.errors;
        this.$snotify.error("Oops something went wrong");
      });
    },
    update: function () {
      this.loading = true;
      product_price
      .update(this.product_price.id, this.product_price)
      .then(response => {
        this.$snotify.success("Price updated");
        this.resetForm();
        this.closeDialog();
        this.loading = false;
        this.$emit('refresh');
      })
      .catch((err) => {
        this.loading = false;
        this.errors = err.errors;
        this.$snotify.error("Oops something went wrong");
      });
    },
    resetForm() {
      this.product_price = {
        product_id : null,
        score_id : null,
        price : null,
        is_free : 0,
        on_special : 0,
        on_special_price : null,
        special_pricing_text : null,
        on_special_start_date : '',
        on_special_end_date : '',
      },
      this.errors=[];
      this.getScores();
      this.$v.$reset();
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    getScores(){
      product_price
      .getScores(this.$route.params.productId)
      .then((response) => {
        this.scores = response.data.scores;
      })
    }
  },
  mounted(){
    this.product_price.product_id = this.$route.params.productId;
    this.getScores();
    this.getCurrentUser();
  }
}
</script>