<template>
    <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  {{product_name}} - Price
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ {{product_name}} \ Price
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                    @click="createOrEditProductPrice()"
                    class="btn btn-primary font-weight-bolder font-size-sm"
                    style="color: #fff;">
                    <i class="fa fa-plus"></i>
                    Add Price
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
        
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Score</th>
                  <th class="px-3">Price</th>
                  <th class="px-3">Special Pricing</th>
                  <th class="px-3">On special</th>
                  <th class="px-3">Special pricing view from date</th>
                  <th class="px-3">Special pricing view up to date</th>
                  <th class="px-3">Free exam</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="item in product_prices" :key="item.id">
                    <td class="px-2">
                      <a @click="createOrEditProductPrice(item)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.score_name}}
                      </a>
                    </td>
                    <td class="px-2">
                      <span >
                        {{ item.score_currency_symbol }} {{item.price ? item.price : '-'}}
                      </span>
                    </td>
                    <td class="px-2">
                      <span v-if="item.on_special_price" class="badge badge-danger">
                        $ {{item.on_special_price}}
                      </span>
                      <span v-else>
                        -
                      </span>
                    </td>
                    <td class="px-2">
                      <span class="badge text-lg"
                            v-bind:class="{ 'badge-success': item.on_special, 'badge-danger': !item.on_special }"
                      >{{ item.on_special ? 'Yes' : 'No' }}</span>
                    </td>
                    <td class="px-2">
                      <span class="font-weight-bold d-block font-size-lg">
                        {{ item.on_special_start_date ? item.formated_on_special_start_date : '-' }}
                      </span>
                    </td>
                    <td class="px-2">
                        <span class="font-weight-bold d-block font-size-lg">
                          {{ item.on_special_end_date ? item.formated_on_special_end_date : '-' }}
                        </span>
                    </td>
                    <td class="px-2">
                      <span class="badge text-lg"
                            v-bind:class="{ 'badge-success': item.is_free, 'badge-danger': !item.is_free }"
                      >{{ item.is_free ? 'Yes' : 'No' }}</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="createOrEditProductPrice(item)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="deleteProductPrice(item.id)">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="product_prices.length == 0">
                      <td colspan="8" class="text-center">
                          No items added
                      </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      
      <create-and-update ref="create-and-update" @refresh="getProductprices"></create-and-update>

    </div>
  </v-app>    
</template>

<script>
import ProductPriceService from "@/services/product/product-price/ProductPriceService";
import CreateAndUpdate from "./CreateAndUpdate";

const product_price = new ProductPriceService();

export default{
  components:{
    CreateAndUpdate
  },
  data(){
    return{
      product_prices: [],
      product_name : '',
      is_product_group_exam:false,
    }
  },
  methods:{
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    getProductprices(){
      product_price
      .paginate(this.productId)
      .then(response => {
        this.product_prices=response.data.product_prices;
        this.product_name = response.data.product_name;
        this.is_product_group_exam=response.data.is_group_exam;
      })
      .catch((err) => {
      });
    },
    createOrEditProductPrice(price = null) {
      if(this.currentUser.access_type == 'score') {
        if(this.product_prices.length > 0){
          this.$refs['create-and-update'].createOrEditProductPrice(this.product_prices[0],this.is_product_group_exam);
        }else {
          this.$refs['create-and-update'].createOrEditProductPrice(null,this.is_product_group_exam);
        }
      } else {
        if(price && price.id) {
          this.$refs['create-and-update'].editProductPrice(price,this.is_product_group_exam);
        } else {
          this.$refs['create-and-update'].createProductPrice(this.is_product_group_exam);
        }
      }
    },
    createProductPrice(){

      this.$refs['create-and-update'].createProductPrice(this.is_product_group_exam);
    },
    editProductPrice(product_price){
      this.$refs['create-and-update'].editProductPrice(product_price,this.is_product_group_exam);
    },
    deleteProductPrice(productPriceId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            product_price
            .delete(productPriceId)
            .then((response) => {
              this.getProductprices();
              this.$snotify.success("Price deleted");
            })
            .catch((err) => {
            });
          }
        },
      });
    },
  },
  mounted(){
    this.getProductprices();
    this.getCurrentUser();
  },
  computed:{
    productId(){
      return this.$route.params.productId;
    }
  },
}
</script>
