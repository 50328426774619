import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class ProductPriceService
{
    #api = null;
    constructor() {
        this.#api = API_URL + 'admin/product-price';
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }
    
    paginate(productId) {
        let url = `${this.#api}/${productId}`;
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    delete(id){
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    getScores(productId){
        let url = `${this.#api}/${productId}/get-scores`;
        return apiService.get(url);
    }   
}
